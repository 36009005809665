var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{staticClass:"ma-0 mb-4 pa-0",attrs:{"cols":"12"}},[(_vm.dataTransferred)?_c('v-alert',{staticClass:"mb-0",attrs:{"type":"warning","text":""}},[_vm._v(" "+_vm._s(_vm.$t('feedbacks.dataTransferred', { type: _vm.$t(("feedbacks.processTypes." + _vm.dataTransferProcessType)) }))+" ")]):_vm._e()],1)],1),_c('v-row',{staticClass:"dark purple"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('feedbacks.ehgCompetencies.1'))+" ")])],1),_c('v-row',{staticClass:"purple"},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_vm._v(" "+_vm._s(_vm.localize(_vm.items[0].label))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[0].tooltip))}})])],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"5"},domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[0].description))}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4","lg":"3"}},[(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.SELF_EVALUATION, 0),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.1')}})],1):_vm._e(),(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-8"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.MANAGER_EVALUATION, 0),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.2')}})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"readOnly":_vm.readOnly,"printView":_vm.printView},on:{"change":function($event){return _vm.itemChanged(0)}},model:{value:(_vm.items[0]),callback:function ($$v) {_vm.$set(_vm.items, 0, $$v)},expression:"items[0]"}})],1)])],1),_c('v-row',{staticClass:"purple last-row"},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_vm._v(" "+_vm._s(_vm.localize(_vm.items[1].label))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[1].tooltip))}})])],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"5"},domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[1].description))}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4","lg":"3"}},[(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.SELF_EVALUATION, 1),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.1')}})],1):_vm._e(),(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-8"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.MANAGER_EVALUATION, 1),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.2')}})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"readOnly":_vm.readOnly,"printView":_vm.printView},on:{"change":function($event){return _vm.itemChanged(1)}},model:{value:(_vm.items[1]),callback:function ($$v) {_vm.$set(_vm.items, 1, $$v)},expression:"items[1]"}})],1)])],1),_c('v-row',{staticClass:"dark orange"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('feedbacks.ehgCompetencies.2'))+" ")])],1),_c('v-row',{staticClass:"orange"},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_vm._v(" "+_vm._s(_vm.localize(_vm.items[2].label))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[2].tooltip))}})])],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"5"},domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[2].description))}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4","lg":"3"}},[(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.SELF_EVALUATION, 2),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.1')}})],1):_vm._e(),(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-8"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.MANAGER_EVALUATION, 2),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.2')}})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"readOnly":_vm.readOnly,"printView":_vm.printView},on:{"change":function($event){return _vm.itemChanged(2)}},model:{value:(_vm.items[2]),callback:function ($$v) {_vm.$set(_vm.items, 2, $$v)},expression:"items[2]"}})],1)])],1),_c('v-row',{staticClass:"orange last-row"},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_vm._v(" "+_vm._s(_vm.localize(_vm.items[3].label))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[3].tooltip))}})])],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"5"},domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[3].description))}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4","lg":"3"}},[(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.SELF_EVALUATION, 3),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.1')}})],1):_vm._e(),(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-8"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.MANAGER_EVALUATION, 3),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.2')}})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"readOnly":_vm.readOnly,"printView":_vm.printView},on:{"change":function($event){return _vm.itemChanged(3)}},model:{value:(_vm.items[3]),callback:function ($$v) {_vm.$set(_vm.items, 3, $$v)},expression:"items[3]"}})],1)])],1),_c('v-row',{staticClass:"dark pink"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('feedbacks.ehgCompetencies.3'))+" ")])],1),_c('v-row',{staticClass:"pink last-row"},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_vm._v(" "+_vm._s(_vm.localize(_vm.items[4].label))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[4].tooltip))}})])],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"5"},domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[4].description))}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4","lg":"3"}},[(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.SELF_EVALUATION, 4),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.1')}})],1):_vm._e(),(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-8"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.MANAGER_EVALUATION, 4),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.2')}})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"readOnly":_vm.readOnly,"printView":_vm.printView},on:{"change":function($event){return _vm.itemChanged(4)}},model:{value:(_vm.items[4]),callback:function ($$v) {_vm.$set(_vm.items, 4, $$v)},expression:"items[4]"}})],1)])],1),_c('v-row',{staticClass:"dark green"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t('feedbacks.ehgCompetencies.4'))+" ")])],1),_c('v-row',{staticClass:"green"},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_vm._v(" "+_vm._s(_vm.localize(_vm.items[5].label))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[5].tooltip))}})])],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"5"},domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[5].description))}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4","lg":"3"}},[(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.SELF_EVALUATION, 5),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.1')}})],1):_vm._e(),(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-8"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.MANAGER_EVALUATION, 5),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.2')}})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"readOnly":_vm.readOnly,"printView":_vm.printView},on:{"change":function($event){return _vm.itemChanged(5)}},model:{value:(_vm.items[5]),callback:function ($$v) {_vm.$set(_vm.items, 5, $$v)},expression:"items[5]"}})],1)])],1),_c('v-row',{staticClass:"green"},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_vm._v(" "+_vm._s(_vm.localize(_vm.items[6].label))+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[6].tooltip))}})])],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"5"},domProps:{"innerHTML":_vm._s(_vm.localize(_vm.items[6].description))}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4","lg":"3"}},[(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.SELF_EVALUATION, 6),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.1')}})],1):_vm._e(),(_vm.feedbackData && _vm.feedbackData.isComparison)?_c('div',{staticClass:"mb-8"},[_c('scale-component',{attrs:{"value":_vm.getComparisonItem(_vm.feedbackTypes.MANAGER_EVALUATION, 6),"small":"","readOnly":true,"printView":_vm.printView,"label":_vm.$t('feedbacks.typeOptions.2')}})],1):_vm._e(),_c('div',{staticClass:"mb-4"},[_c('scale-component',{attrs:{"readOnly":_vm.readOnly,"printView":_vm.printView},on:{"change":function($event){return _vm.itemChanged(6)}},model:{value:(_vm.items[6]),callback:function ($$v) {_vm.$set(_vm.items, 6, $$v)},expression:"items[6]"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }